import { styled, withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';

export const StyledContainer = styled('div', {
  background: 'linear-gradient(180deg, #E8842C 0%, #FFFAF6 100%)',
  position: 'absolute',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
});

export const StyledLoadingContainer = styled('div', {
  width: '100%',
  height: 'auto',
  display: 'flex',
  marginTop: '10px',
  justifyContent: 'center',
});

export const StyledFormContainer = styled('div', {
  height: 'auto',
  width: '480px',
  background: '#FFFFFF',
  borderRadius: '10px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingTop: '56px',
  paddingLeft: '60px',
  paddingRight: '60px',
  paddingBottom: '56px',
  boxSizing: 'border-box',
  '@media screen and (max-width: 720px)': {
    width: '90%',
    left: '5%',
    marginLeft: '0px',
    top: '5%',
    marginTop: '0px',
    transform: 'none',
  },
});

export const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const ExtraLargeSpinner = withStyle(Spinner, {
  width: '16px',
  height: '16px',
  borderLeftWidth: '2px',
  borderRightWidth: '2px',
  borderTopWidth: '2px',
  borderBottomWidth: '2px',
  borderTopColor: '#ee9844',
  borderBottomColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  marginRight: '10px',
});
