import { ReactNode } from 'react';
import { Toast, KIND, ToastProps } from 'baseui/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { expand } from 'inline-style-expand-shorthand';

export const SIZE = {
  compact: 'compact',
  default: 'default',
};

export interface StyledToastProps extends ToastProps {
  message: ReactNode;
  size?: keyof typeof SIZE;
}

const styleMap = {
  [KIND.positive]: {
    color: '#30A442',
    backgroundColor: '#EBF6ED',
  },
  [KIND.negative]: {
    color: '#B93535',
    backgroundColor: '#F8EBEA',
  },
  [KIND.warning]: {
    color: '#E49A0A',
    backgroundColor: '#FDF5E7',
  },
  [KIND.info]: {
    color: '#0A88E4',
    backgroundColor: '#E7F4FC',
  },
};

const sizeMap = {
  [SIZE.default]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  [SIZE.compact]: {
    fontSize: '14px',
    lineHeight: '16px',
  },
};

const iconMap = {
  [KIND.positive]: faCircleCheck,
  [KIND.negative]: faCircleExclamation,
  [KIND.warning]: faTriangleExclamation,
  [KIND.info]: faCircleInfo,
};

export const StyledToast = (props: StyledToastProps) => {
  const { kind = 'info', message, children, size = SIZE.default } = props;

  return (
    <Toast
      {...props}
      overrides={{
        Body: {
          style: {
            width: '100%',
            fontWeight: 400,
            color: '#222222',
            backgroundColor: styleMap[kind].backgroundColor,
            borderLeft: `4px solid ${styleMap[kind].color}`,
            boxSizing: 'border-box',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            ...sizeMap[size],
            ...expand({
              margin: '0',
              borderRadius: '4px',
            }),
          },
        },
        CloseIcon: {
          style: {
            width: sizeMap[size].lineHeight,
            height: sizeMap[size].lineHeight,
            color: '#3F5870',
          },
        },
        InnerContainer: {
          style: {
            width: '100%',
          },
        },
        ...props.overrides,
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <ToastIcon kind={kind} />
        <div
          style={{
            flex: 1,
          }}
        >
          <>
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {message}
            </div>
            {children}
          </>
        </div>
      </div>
    </Toast>
  );
};

const ToastIcon = ({ kind }: { kind: keyof typeof KIND }) => {
  const specificIcon = iconMap[kind];
  return (
    <div style={{ marginRight: '8px' }}>
      <FontAwesomeIcon icon={specificIcon as IconProp} color={styleMap[kind].color} />
    </div>
  );
};

export default StyledToast;
