import axios from 'axios';

export type Return<T = unknown> = {
  error: boolean;
  message: string;
  results: T;
};

export type ChangePasswordRequest = {
  name: string;
  currentPassword: string;
  password: string;
};
export const changePasswordReq = async (data: ChangePasswordRequest) => {
  const { name, currentPassword: oldPassword, password } = data;
  const result = await axios.put<Return<void>>(
    `/api/gsql-server/gsql/scim/v2/Users/${name}`,
    { password },
    {
      headers: {
        Authorization: `Basic ${btoa(`${name}:${oldPassword}`)}`,
      },
      params: {
        gsqlFormat: true,
      },
    }
  );
  return result.data;
};

export type LoginRequest = {
  username: string;
  password: string;
};
export const loginReq = async (data: LoginRequest) => {
  const result = await axios.post<Return<void>>('/api/auth/login', data);
  return result.data;
};
