import { AxiosError } from 'axios';

export function getHashSearchParam(key: string) {
  const search = getHashSearchStr();
  const usp = new URLSearchParams(search);
  return usp.get(key);
}

export function getMainDomain(url: string) {
  try {
    let hostname = new URL(url).hostname;
    return hostname
      .replace(/^(?:www\.)?/i, '')
      .split('.')
      .slice(-2)
      .join('.');
  } catch (error) {
    console.error(error);
    return 'tgcloud.io';
  }
}

export function getHashSearchStr() {
  const url = window.location.href;
  const hash = url.substring(url.indexOf('#') + 1);
  const searchIndex = hash.indexOf('?');
  return searchIndex !== -1 ? hash.substring(searchIndex + 1) : '';
}

export function getErrorMessage(error: AxiosError<any, any> | Error) {
  let message = error.message;

  if ('response' in error) {
    // check for axios error
    if (error.response?.data?.message) {
      // error message from rest api
      message = error.response?.data?.message;
    } else if (error.response?.data?.Message) {
      message = error.response?.data?.Message;
    }
  } else if (error instanceof AxiosError) {
    message = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
  }

  if (!message) {
    message = '';
  }

  return message;
}

export function getReturnUrl() {
  const routeArr = ['/studio/', '/admin/', '/gsql/', '/insights/', '/graphql/', '/workbench', '/mlwb'];
  let returnURL = decodeURIComponent(getHashSearchParam('returnURL') || '');
  const redirectToApp = routeArr.some((route) => {
    return returnURL.indexOf(route) === 0;
  });
  if (redirectToApp) {
    return returnURL;
  }
  return '/#/apps';
}

export function getSavedUsername() {
  const username = window.localStorage.getItem('TigerGraphUIUsername') || '';
  try {
    return JSON.parse(username);
  } catch (error) {
    return '';
  }
}
