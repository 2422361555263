import { toaster } from 'baseui/toast';
import StyledToast, { StyledToastProps } from './styledToast';
import { expand } from 'inline-style-expand-shorthand';

export const showToast = (props: StyledToastProps) => {
  toaster.show(<StyledToast {...props} />, {
    overrides: {
      Body: {
        style: {
          width: 'fit-content',
          height: 'fit-content',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ...expand({
            padding: '0',
          }),
        },
      },
      CloseIcon: <></>,
      InnerContainer: {
        style: {
          ...expand({
            margin: '0',
          }),
        },
      },
    },
  });
};

export default showToast;
