import { useCallback, useEffect, createRef } from 'react';
import { Input } from '@tigergraph/app-ui-lib/input';
import { FormControl } from 'baseui/form-control';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from 'baseui';
import { useQuery, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { Spinner } from 'baseui/spinner';
import { getHashSearchParam } from '../../utils/utils';
import fotlogin from '../../assets/fotlogin.svg';
import errorsvg from '../../assets/error.svg';
import { compare } from 'compare-versions';
import {
  CenterContainer,
  ExtraLargeSpinner,
  StyledContainer,
  StyledFormContainer,
  StyledLoadingContainer,
} from './styled';
import { useParticleJS } from './hook';
import { getReturnUrl } from './../../utils/utils';
import { getOIDCRequest, getSAMLRequest } from '@tigergraph/tools-models';

type LoginError = {
  error: boolean;
  message: string;
  results: Object;
};

type Response = {
  error: boolean;
  message: string;
  results: {
    isGlobalDesigner: boolean;
    isSuperUser: boolean;
    name: string;
    roles: {
      [key: string]: string[];
    };
    securityRecommendations: string[];
  };
};

type OIDCResponse = {
  error: boolean;
  message: string;
  results: {
    OIDCRequest: string;
    ssoBinding: string;
  };
};

type VersionResponse = {
  error: boolean;
  message: string;
  results: {
    tigergraph_version: string;
  };
};

export default function Login() {
  useParticleJS();

  const formRef = createRef<HTMLFormElement>();

  const getAuthnRequestURL = '/api/auth/login';
  const logOutURL = '/api/auth/logout';
  const versionURL = '/api/version';

  const [username, setUserName] = useState(process.env.REACT_APP_TG_USERNAME);
  const [password, setPassword] = useState(getHashSearchParam('loggedOut') ? '' : process.env.REACT_APP_TG_PASSWORD);
  const [loading, setLoading] = useState(false);
  const autoLogin = process.env.REACT_APP_ENV === 'cloud' && !getHashSearchParam('loggedOut');
  const [oidcLoading, setOidcLoading] = useState(process.env.REACT_APP_ENV === 'cloud' && autoLogin);
  const [loginerror, setLoginerror] = useState(false);
  const [errormsg, setErrormsg] = useState('User authentication failed.');
  const [showPasswordExpired, setShowPasswordExpired] = useState(false);

  const [css] = useStyletron();

  const versionResponse = useQuery('version', () => axios.get<VersionResponse>(versionURL));
  const version = versionResponse.data?.data?.results?.tigergraph_version;
  const supportOIDC = version && compare(version, '3.10.0', '>=');

  useQuery('LOGOUT', () => axios.post(logOutURL), {
    onSettled: () => {
      ssoResponse.mutate();
      oidcResponse.mutate();
    },
    retry: true,
    retryDelay(failureCount, error) {
      return Math.min(1000 * 2 ** failureCount, 30000);
    },
  });

  const ssoResponse = useMutation('SSO', () => getSAMLRequest().then((response) => response && (response.data as any)));

  const oidcResponse = useMutation(
    'OIDC',
    () => getOIDCRequest().then((response) => response && (response.data as OIDCResponse)),
    {
      onSuccess: (response) => {
        if (autoLogin) {
          onOIDCLogin(response);
        }
      },
      onError: () => {
        setOidcLoading(false);
      },
    }
  );

  const loginAPI = async () => {
    try {
      setLoading(true);
      const response = await axios.post<Response>(`${getAuthnRequestURL}`, {
        username,
        password,
      });
      if (response.data) {
        window.localStorage.setItem('TigerGraphUIUsername', JSON.stringify(response.data.results.name));
        const { securityRecommendations } = response.data.results;
        if (securityRecommendations) {
          window.localStorage.setItem('TigerGraphSecurityRecommendations', JSON.stringify(securityRecommendations));
        }
        let returnURL = getReturnUrl();
        window.location.replace(`${window.location.origin}${returnURL}`);
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      const errdata = err.response?.data as LoginError;
      if (errdata) {
        if (errdata.message.toLowerCase().includes('password has expired')) {
          setShowPasswordExpired(true);
          window.localStorage.setItem('TigerGraphUIUsername', JSON.stringify(username));
        } else {
          setErrormsg(errdata.message);
        }
      }
      setLoading(false);
      setLoginerror(true);
    }
  };

  useEffect(() => {
    if (!loginerror) {
      setShowPasswordExpired(false);
    }
  }, [loginerror]);

  useEffect(() => {
    setLoginerror(false);
  }, [username, password]);

  function handlFormChange(type: string) {
    return (e) => {
      switch (type) {
        case 'username':
          setUserName(e.target.value);
          break;
        case 'password':
          setPassword(e.target.value);
          break;
        default:
          break;
      }
    };
  }

  async function ssoLogin() {
    formRef.current?.submit();
  }

  function onOIDCLogin(response?: OIDCResponse) {
    let oidcResponseRes = response || oidcResponse.data;
    let returnState = getHashSearchParam('returnURL') || '/apps';
    if (returnState === '/apps') {
      returnState = '/#' + returnState;
    }
    if (returnState.indexOf('/mlwb') !== -1) {
      returnState = `${sessionStorage.getItem('BASEURL')}/mlwb/`;
    } else {
      returnState = window.location.origin + returnState;
    }
    const state = btoa(returnState);
    if (oidcResponseRes) {
      // for BYOC, we replace the redirect_uri in the OIDCRequest to make it point to the controller
      let { OIDCRequest } = oidcResponseRes.results;
      const redirectUriRegex = /redirect_uri=([^&]+)/;
      const redirectUriMatch = OIDCRequest.match(redirectUriRegex);
      if (redirectUriMatch) {
        const originalRedirectUri = decodeURIComponent(redirectUriMatch[1]);
        if (
          originalRedirectUri.match(
            /tg-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.[a-zA-Z0-9.-]+\.(privatelink|i|byoc)/
          )
        ) {
          OIDCRequest = OIDCRequest.replace(
            redirectUriRegex,
            `redirect_uri=${encodeURIComponent(getControllerURL() + '/auth/oidc/callback')}`
          );
        }
      }
      const ssoOIDCRequest = OIDCRequest + `&state=${state}`;
      window.open(ssoOIDCRequest, '_self');
    }
  }

  function getControllerURL() {
    const cloudEnv = sessionStorage.getItem('CLOUDENV');

    if (cloudEnv === 'dev') {
      return 'https://api-v4.tgcloud-dev.com/controller';
    } else if (cloudEnv === 'uat') {
      return 'https://api-v4-uat.tgcloud-dev.com/controller';
    }
    return 'https://api.tgcloud.io/controller/v4';
  }

  /**
   * Retrieve error and return url.
   * Auto login if it is not logged out by user.
   *
   * @private
   * @memberof LoginComponent
   */
  const retrieveParamsFromRouteAndAutoLogin = useCallback(() => {
    const errMsg = getHashSearchParam('loginFailure');
    if (errMsg) {
      console.log(errMsg);
    }
    if (!getHashSearchParam('loggedOut') && process.env.REACT_APP_ENV === 'testdrive') {
      loginAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_ENV === 'cloud']);

  function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && username !== '' && password !== '') {
      event.preventDefault();
      event.stopPropagation();
      loginAPI();
    }
  }

  useEffect(() => {
    retrieveParamsFromRouteAndAutoLogin();
  }, [retrieveParamsFromRouteAndAutoLogin]);

  return (
    <>
      {oidcLoading ? (
        <StyledLoadingContainer>
          <Spinner />
        </StyledLoadingContainer>
      ) : (
        <StyledContainer id="particles-js">
          <StyledFormContainer>
            <div
              className={css({
                paddingBottom: '24px',
                borderBottom: '1px solid rgba(212, 218, 223, 0.4)',
              })}
            >
              <CenterContainer>
                <img
                  className={css({
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  })}
                  src={fotlogin}
                  alt=""
                />
              </CenterContainer>
            </div>
            {process.env.REACT_APP_ENV === 'cloud' ? (
              <Button
                onClick={() => {
                  onOIDCLogin();
                }}
                overrides={{
                  BaseButton: {
                    style: () => ({
                      backgroundColor: '#FB9A44',
                      width: '360px',
                      height: '40px',
                      color: '#222222',
                      borderRadius: '5px',
                      marginTop: '10px',
                    }),
                  },
                }}
              >
                Login with TigerGraph Cloud
              </Button>
            ) : (
              <>
                {ssoResponse.data ? (
                  <>
                    <Button
                      onClick={ssoLogin}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            backgroundColor: '#FFFFFF',
                            borderLeft: '2px solid #FB9A44',
                            borderRight: '2px solid #FB9A44',
                            borderTop: '2px solid #FB9A44',
                            borderBottom: '2px solid #FB9A44',
                            width: '360px',
                            height: '40px',
                            color: '#222222',
                            borderRadius: '5px',
                            marginTop: '24px',
                            ':hover': {
                              backgroundColor: '#FFFFFF',
                            },
                          }),
                        },
                      }}
                    >
                      Login With SSO
                    </Button>
                    <div
                      className={css({
                        borderBottom: '1px solid rgba(212, 218, 223, 0.4)',
                        marginTop: '32px',
                        marginBottom: '32px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                      })}
                    >
                      <div
                        className={css({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#767676',
                          backgroundColor: '#FFFFFF',
                          marginBottom: '-8px',
                        })}
                      >
                        or Login with username
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ height: '72px' }}>
                  <FormControl
                    label="Username"
                    caption=""
                    overrides={{
                      Label: {
                        style: () => ({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#222222',
                        }),
                      },
                    }}
                  >
                    <Input
                      value={username}
                      onChange={handlFormChange('username')}
                      overrides={{
                        Input: {
                          style: () => ({
                            '&::-webkit-input-placeholder': {
                              color: '#767676',
                            },
                            '&::-moz-placeholder': {
                              color: '#767676',
                            },
                            '&::ms-input-placeholder': {
                              color: '#767676',
                            },
                          }),
                        },
                        Root: {
                          style: () => {
                            return {
                              borderLeftColor: '#AAB5BF',
                              borderRightColor: '#AAB5BF',
                              borderTopColor: '#AAB5BF',
                              borderBottomColor: '#AAB5BF',
                            };
                          },
                        },
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                  </FormControl>
                </div>
                <div style={{ height: '83px' }}>
                  <FormControl
                    label="Password"
                    caption=""
                    overrides={{
                      Label: {
                        style: () => ({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#222222',
                        }),
                      },
                    }}
                  >
                    <Input
                      type="password"
                      value={password}
                      autoComplete="off"
                      onChange={handlFormChange('password')}
                      overrides={{
                        MaskToggleHideIcon: () => (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 8.74 9.24 6.5 12 6.5C14.76 6.5 17 8.74 17 11.5C17 14.26 14.76 16.5 12 16.5ZM12 8.5C10.34 8.5 9 9.84 9 11.5C9 13.16 10.34 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 9.84 13.66 8.5 12 8.5Z"
                              fill="#3F5870"
                            />
                          </svg>
                        ),
                        MaskToggleShowIcon: () => (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8V9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z"
                              fill="#3F5870"
                            />
                          </svg>
                        ),
                        Input: {
                          style: () => ({
                            '&::-webkit-input-placeholder': {
                              color: '#767676',
                            },
                            '&::-moz-placeholder': {
                              color: '#767676',
                            },
                            '&::ms-input-placeholder': {
                              color: '#767676',
                            },
                          }),
                        },
                        Root: {
                          style: () => {
                            return {
                              borderLeftColor: '#AAB5BF',
                              borderRightColor: '#AAB5BF',
                              borderTopColor: '#AAB5BF',
                              borderBottomColor: '#AAB5BF',
                            };
                          },
                        },
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                  </FormControl>
                </div>
                {loginerror && (
                  <div
                    className={css({
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      color: '#B93535',
                      height: '16px',
                      marginTop: '-2px',
                      marginBottom: '10px',
                    })}
                  >
                    <img className={css({ marginRight: '2px' })} src={errorsvg} alt="" />
                    {showPasswordExpired ? (
                      <span>
                        Your password has expired. Please <a href="/#/changepassword">click here</a> to change your
                        password.
                      </span>
                    ) : (
                      errormsg
                    )}
                  </div>
                )}
                <div
                  className={css({
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <CenterContainer>
                    <Button
                      onClick={loginAPI}
                      type="submit"
                      disabled={!!(!username || !password) || loading}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            backgroundColor: '#FB9A44',
                            width: '360px',
                            '@media screen and (max-width: 720px)': {
                              width: '100%',
                            },
                            height: '40px',
                            color: '#222222',
                            borderRadius: '5px',
                            marginTop: '10px',
                          }),
                        },
                      }}
                    >
                      {loading ? (
                        <>
                          <ExtraLargeSpinner />
                          Logging in
                        </>
                      ) : (
                        'Login'
                      )}
                    </Button>
                    {process.env.REACT_APP_ENV !== 'cloud' && supportOIDC && oidcResponse.data && (
                      <Button
                        onClick={() => {
                          onOIDCLogin();
                        }}
                        overrides={{
                          BaseButton: {
                            style: () => ({
                              backgroundColor: '#FB9A44',
                              width: '360px',
                              height: '40px',
                              color: '#222222',
                              borderRadius: '5px',
                              marginTop: '10px',
                            }),
                          },
                        }}
                      >
                        Login with OIDC
                      </Button>
                    )}
                  </CenterContainer>
                  {ssoResponse.data ? (
                    <>
                      <form
                        className={css({
                          width: '0px',
                          height: '0px',
                          position: 'absolute',
                          zIndex: '-2',
                          visibility: 'hidden',
                        })}
                        id="loginform"
                        action={ssoResponse.data.results.ssoUrl}
                        ref={formRef}
                        method={ssoResponse.data.results.ssoSAMLBinding || 'POST'}
                      >
                        <input readOnly name="SAMLRequest" value={ssoResponse.data.results.SAMLRequest} />
                        <input readOnly name="SigAlg" value={ssoResponse.data.results.SigAlg} />
                        <input readOnly name="Signature" value={ssoResponse.data.results.Signature} />
                        <input readOnly name="RelayState" value={btoa(getReturnUrl())} />
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </StyledFormContainer>
        </StyledContainer>
      )}
    </>
  );
}
